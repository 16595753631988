import React from "react";
import Logo from "../assets/logo.png";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import '../css/Footer.css'

const Footer = () => {
  return (
    <div className="footer-body">
      <div className="footer-wrapper">
        <div className="footer-section-one">
          <div className="footer-logo-container">
            <img src={Logo} alt="Adkemis" />
            <p className="copyText">&copy; 2024 Adkemis. All rights reserved. | <a href="https://www.linkedin.com/in/vinton-roberts-bsc-asc-67a00946/" target="_blank" rel="noopener noreferrer" className="designer">coded by: vinton.roberts</a></p>
          </div>
          <div className="footer-contact-icons">
            <p className="footerContact">Phone: 876-000-0000</p>
            <p className="footerContact">Email: info@adkemis.com</p>
            <div className="footer-icons">
              <RiInstagramFill />
              <FaFacebook />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
