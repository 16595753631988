import React from 'react';
import { useNavigate } from 'react-router-dom';
import './css/Dashboard.css';
import AddNewListingIcon from './images/add.png';
import ViewAllIcon from './images/view.png';
import ViewAllUsersIcon from './images/users.png';

const Dashboard = () => {
  const navigate = useNavigate();

  return (
    <section>
      <div className="dashboard-container">
        <div className="dashboard-header">
          <h3>Dashboard</h3>
        </div>
        <div className="dashboard-icons">
          <div className="dashboard-icon" onClick={() => navigate('/dashboard/AllRecords')}>
            <img src={ViewAllIcon} alt="Show All Listings" />
            <span>All Ads</span>
          </div>
          <div className="dashboard-icon" onClick={() => navigate('/dashboard/users')}>
            <img src={ViewAllUsersIcon} alt="Show All Users" />
            <span>All Users</span>
          </div>
          <div className="dashboard-icon" onClick={() => navigate('/dashboard/add-new-listing')}>
            <img src={AddNewListingIcon} alt="Add New Listing" />
            <span>Add Vehicle</span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
