import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import '../css/VehicleDetails.css'; // Import the CSS file for styling

const VehicleDetails = () => {
  const { id } = useParams();
  const [vehicle, setVehicle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPhone, setShowPhone] = useState(false); 
  const [selectedImage, setSelectedImage] = useState(null); // State for the maximized image

  useEffect(() => {
    const fetchVehicleDetails = async () => {
      const vehicleRef = doc(db, 'vehicles', id);
      const vehicleSnap = await getDoc(vehicleRef);

      if (vehicleSnap.exists()) {
        setVehicle(vehicleSnap.data());
      } else {
        console.log('No such document!');
      }

      setLoading(false);
    };

    fetchVehicleDetails();
  }, [id]);

  if (loading) {
    return <p className="loading">Loading...</p>;
  }

  if (!vehicle) {
    return <p className="error">Vehicle not found.</p>;
  }

  return (
    <div className="vehicleDetails">
      <h1 className="vehicleTitle">{vehicle.brand} - {vehicle.model}</h1>
      
      {/* Main Image */}
      <img 
        src={vehicle.imageUrls[0]} 
        alt={vehicle.model} 
        className="vehicleImage" 
        onClick={() => setSelectedImage(vehicle.imageUrls[0])} 
      />

      {/* Additional Images */}

       {/* Modal for Maximized Image */}
       {selectedImage && (
        <div className="imageModal" onClick={() => setSelectedImage(null)}>
          <img src={selectedImage} alt="Maximized" className="maximizedImage" />
        </div>
      )}

      <div className="imageGallery">
        {vehicle.imageUrls.slice(1).map((url, index) => (
          <img 
            key={index} 
            src={url} 
            alt={`${vehicle.model}-${index}`} 
            className="thumbnail" 
            onClick={() => setSelectedImage(url)} 
          />
        ))}
      </div>

      <p className="vehicleInfo">Location: {vehicle.parish}</p>
      <p className="vehicleInfo">Posted By: {vehicle.postedbyName}</p>      
      <p className="vehicleInfo">Year: {vehicle.year}</p>
      <p className="vehicleInfo">Price: ${vehicle.price}</p>
      <p className="vehicleInfo">Description: {vehicle.description}</p>

      <p className="vehicleInfoHeader">More Info</p>      
      <p className="vehicleInfo">AC: {vehicle.ac}</p>
      <p className="vehicleInfo">Alarm: {vehicle.alarm}</p>
      <p className="vehicleInfo">Color: {vehicle.color}</p>
      <p className="vehicleInfo">Air Bags: {vehicle.airbag}</p>
      <p className="vehicleInfo">Power win/doors: {vehicle.power_win_doors}</p>
      <p className="vehicleInfo">DriveTrain: {vehicle.drivetrain}</p>
      <p className="vehicleInfo">Right Hand Drive: {vehicle.rightHandDrive}</p>
      <p className="vehicleInfo">Fuel Type: {vehicle.fuelType}</p>
      <p className="vehicleInfo">Gearbox: {vehicle.gearbox}</p>
      <p className="vehicleInfo">Engine Size: {vehicle.engineSize}</p>
      <p className="vehicleInfo">Seats: {vehicle.seats}</p>
      <p className="vehicleInfo">Mileage: {vehicle.mileage}</p>


      <div className="contactRow">
        <button 
          className="contactButton" 
          onClick={() => setShowPhone(!showPhone)}
        >
          {showPhone ? 'Hide Phone Number' : 'Contact Seller'}
        </button>
        {showPhone && (
          <p className="contactInfo">Phone: {vehicle.phone}</p>
        )}
      </div>

    </div>
  );
};

export default VehicleDetails;
